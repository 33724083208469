<template>
  <div class="camera-wrap">
    <!-- <EZUIKit ref="ysy" :width="1080" :height="600" videoUrl="ezopen://open.ys7.com/E98028749/1.hd.live" />
    <div class="btn-group">
      <el-button type="primary" size="default" @click="changeStatus('up')" circle icon="el-icon-arrow-up"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('down')" circle icon="el-icon-arrow-down"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('left')" circle icon="el-icon-arrow-left"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('right')" circle icon="el-icon-arrow-right"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('stop')" circle icon="el-icon-video-pause"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('zoomIn')" circle icon="el-icon-zoom-in"></el-button>
      <el-button type="primary" size="default" @click="changeStatus('zoomOut')" circle icon="el-icon-zoom-out"></el-button>
    </div> -->
    <div class="camera-box">
      <hik-vision ref="hik" :width="1000" :height="600" :pointCode="'a6da76ca7ccc44619a3971d7c46fbb04'"></hik-vision>
    </div>
  </div>
</template>

<script>
import HikVision from '../components/HikVision'
export default {
  components: {
    HikVision
  },
  methods: {
    start () {
      this.$refs.hik.startpreview()
    }
  }
  // methods: {
  //   changeStatus (opt) {
  //     switch (opt) {
  //       case 'up':
  //         this.changeDirection(0)
  //         break
  //       case 'down':
  //         this.changeDirection(1)
  //         break
  //       case 'left':
  //         this.changeDirection(2)
  //         break
  //       case 'right':
  //         this.changeDirection(3)
  //         break
  //       case 'stop':
  //         this.stop()
  //         break
  //       case 'zoomIn':
  //         this.changeDirection(8)
  //         break
  //       case 'zoomOut':
  //         this.changeDirection(9)
  //         break
  //     }
  //   },
  //   changeDirection (direction) {
  //     const param = new FormData()
  //     param.append('accessToken', this.$refs.ysy.token)
  //     param.append('direction', direction)
  //     param.append('deviceSerial', 'F06352655')
  //     param.append('channelNo', '1')
  //     param.append('speed', 1)
  //     this.$axios.post('https://open.ys7.com/api/lapp/device/ptz/start', param)
  //   },
  //   stop () {
  //     const param = new FormData()
  //     param.append('accessToken', this.$refs.ysy.token)
  //     param.append('deviceSerial', 'F06352655')
  //     param.append('channelNo', '1')
  //     param.append('speed', 0)
  //     this.$axios.post('https://open.ys7.com/api/lapp/device/ptz/stop', param)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.camera-wrap {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .camera-box {
    width: 70%;
    height: 70%;
  }
  .btn-group {
    margin-top: 10px;
  }
}
</style>
